<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="帮助中心" left-text="返回" left-arrow fixed @click-left="goHome"/>

        <div class="content">
            <div class="main-content">
                <div class="help-center">
                    <van-collapse v-model="activeNames">
                        <van-collapse-item title="如何注册" name="1">点首页底部菜单消息中心，我的报名，个人中心任意一个，进入登录页，点击没有账号，立即注册即可注册。</van-collapse-item>
                        <van-collapse-item title="如何登录？" name="2">点首页底部菜单消息中心，我的报名，个人中心任意一个，即进入登录页，输入登录信息登录。</van-collapse-item>
                        <van-collapse-item title="如何找回密码？" name="3">点首页底部菜单消息中心，我的报名，个人中心任意一个，进入登录页，点忘记密码，即可进入重置密码步骤，验证手机号后可重新设置密码。</van-collapse-item>
                        <van-collapse-item title="如何查看我的报名？" name="4">点首页底部菜单我的报名，在登录页输入账号密码成功登录后，即可查看。</van-collapse-item>
                        <van-collapse-item title="如何查看报名通知？" name="5">点首页底部菜单消息中心，在登录页输入账号密码成功登录后，即可查看。</van-collapse-item>
                        <van-collapse-item title="报名后多久收到通知？" name="6">提交报名资料后，需要人工后台审核，审核结果会以站内消息方式通知，点首页底部菜单消息中心即可查看。</van-collapse-item>
                        <van-collapse-item title="可以报名多个职位吗？" name="7">招聘项目是否容许报名多个职位，取决于项目设置，如招聘项目允许每人可报名多个职位，则可以，否则不行。</van-collapse-item>
                    </van-collapse>
                </div>
            </div>
        </div>
        <div class="footer"></div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            projectCode: null,
            activeNames: []
        }
    },
    methods: {
        goHome() {
            window.history.back(-1)
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
    }
}
</script>

<style scoped>


</style>
