<template>
    <div class="wrapper">
        <Header :code="projectCode"></Header>
        <div class="content">
            <div class="main-content">

                <div class="help-center">
                    <div class="headline">帮助中心</div>
                    <div class="section">
                        <Collapse simple>
                            <Panel name="1">
                                如何注册？
                                <p slot="content">点击右上角注册按钮即可注册。</p>
                            </Panel>
                            <Panel name="2">
                                如何登录？
                                <p slot="content">点击右上角登录按钮即，在登录弹窗中输入账号、密码即可登录。</p>
                            </Panel>
                            <Panel name="3">
                                如何找回密码？
                                <p slot="content">点击右上角登录按钮，在登录弹窗中点击忘记密码即可进入重置密码步骤，验证手机号后可重新设置密码。</p>
                            </Panel>
                            <Panel name="4">
                                如何查看我的报名？
                                <p slot="content">点击右上角登录按钮，在登录弹窗输入账号密码成功登录后，点击右上角用户名右侧下拉菜单按钮，在下拉菜单中点我的报名即可查看。</p>
                            </Panel>
                            <Panel name="5">
                                如何查看报名通知？
                                <p slot="content">点击右上角登录按钮，在登录弹窗输入账号密码成功登录后，点击右上角消息按钮，即可进入消息中心查看消息。</p>
                            </Panel>
                            <Panel name="6">
                                报名后多久收到通知？
                                <p slot="content">提交报名资料后，需要人工后台审核，审核结果会以站内消息方式通知，登录系统后点击右上角有消息通知按钮，可查看消息。</p>
                            </Panel>
                            <Panel name="7">
                                可以报名多个职位吗？
                                <p slot="content">招聘项目是否容许报名多个职位，取决于项目设置，如果招聘项目允许每人可报名多个职位，则可以，否则不行。</p>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer";

export default {
    components: {
        Header: Header,
        Footer: Footer,
    },
    data() {
        return {
            showLoginModal: false,
            projectCode: null,
        }
    },
    methods: {
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
    }
};
</script>

<style scoped>

</style>
